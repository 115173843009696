export default function useFileDownload() {
  const downloadFile = (blob, fileName, mimeType) => {
    if (window.ReactNativeWebView) {
      // Convert Blob to base64 for React Native
      const reader = new FileReader()
      reader.onloadend = () => {
        const base64Data = reader.result.split(',')[1]
        const data = {
          type: 'DOWNLOAD_FILE',
          fileName,
          fileData: base64Data,
          mimeType,
        }
        window.ReactNativeWebView.postMessage(JSON.stringify(data))
      }
      reader.readAsDataURL(blob)
    } else {
      // Download file in the browser
      const downloadLink = document.createElement('a')
      downloadLink.href = window.URL.createObjectURL(blob)
      downloadLink.download = fileName
      downloadLink.click()
      window.URL.revokeObjectURL(downloadLink.href)
    }
  }

  return {
    downloadFile,
  }
}
