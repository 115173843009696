<template>
  <div>
    <!-- Total amounts -->
    <v-row class="mb-3">
      <v-col
        cols="12"
        sm="4"
        md="4"
      >
        <v-card
          flat
          class="mx-auto rounded-lg"
        >
          <v-card-text class="align-center justify-space-between pa-4 text-center">
            <v-avatar
              color="primary"
            >
              <v-icon
                size="25"
                color="white--text"
                class="rounded-0 white--text"
              >
                {{ icons.mdiCurrencyUsd }}
              </v-icon>
            </v-avatar>
            <div style="direction: rtl">
              <span>
                {{ $t('totalPayment') }}
              </span>
              <h2 class="font-weight-semibold mb-1 mt-1 pt-2">
                <span class="me-n1">

                  {{ sumTotalPayment }}
                </span>
                <span
                  class="text-xl me-n1"
                >₪</span>
              </h2>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="4"
        md="4"
      >
        <v-card
          flat
          class="mx-auto rounded-lg"
        >
          <v-card-text class="align-center justify-space-between pa-4 text-center">
            <v-avatar
              color="primary"
            >
              <v-icon
                size="25"
                color="white--text"
                class="rounded-0 white--text"
              >
                {{ icons.mdiCheck }}
              </v-icon>
            </v-avatar>
            <div style="direction: rtl">
              <span>
                {{ $t('Total') }}  {{ $t('paidAmount') }}
              </span>
              <h2 class="font-weight-semibold mb-1 mt-1 pt-2">
                <span class="me-n1">
                  {{ sumPaidAmount }}
                </span>
                <span
                  class="text-xl me-n1"
                >₪</span>
              </h2>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        sm="4"
        md="4"
      >
        <v-card
          flat
          class="mx-auto rounded-lg"
        >
          <v-card-text class="align-center justify-space-between pa-4 text-center">
            <v-avatar
              color="primary"
            >
              <v-icon
                size="25"
                color="white--text"
                class="rounded-0 white--text"
              >
                {{ icons.mdiProgressClock }}
              </v-icon>
            </v-avatar>
            <div style="direction: rtl">
              <span>
                {{ $t('Total') }}  {{ $t('leftToPay') }}

              </span>
              <h2 class="font-weight-semibold mb-1 mt-1 pt-2">
                <span class="me-n1">
                  {{ sumLeftToPay }}
                </span>
                <span
                  class="text-xl me-n1"
                >₪</span>
              </h2>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-card>
      <!-- Table of expenses -->
      <v-data-table
        class="pb-2"
        :headers="tableColumns"
        :items="items"
        hide-default-footer
        :no-data-text="$t('NoData')"
        :items-per-page="itemsPerPage"
        :page.sync="page"
        @page-count="pageCount = $event"
      >
        <template v-slot:top>
          <div
            class="d-flex py-5 px-3 flex-wrap"
          >
            <v-btn
              color="primary"
              outlined
              rounded
              class="me-2 px-2 mt-2"
              @click="addNewRow"
            >
              <v-icon
                size="20"
                class="me-1"
              >
                {{ icons.mdiPlus }}
              </v-icon>
              <span>
                {{ $t('Add') }}
              </span>
            </v-btn>
            <v-btn
              color="primary"
              rounded
              outlined
              class="me-2 px-2 mt-2"
              @click="saveItem"
            >
              <v-icon
                size="20"
                class="me-1"
              >
                {{ icons.mdiContentSaveOutline }}
              </v-icon>
              <span>
                {{ $t('Saving') }}
              </span>
            </v-btn>
            <v-btn
              color="primary"
              rounded
              class="me-2 px-2 mt-2"
              outlined
              @click="exportToExcel"
            >
              <v-icon
                size="20"
                class="me-1"
              >
                {{ icons.mdiFileMoveOutline }}
              </v-icon>
              <span>
                {{ $t('Export') }}
              </span>
            </v-btn>
          </div>
        </template>

        <template #[`item.expensesNumber`]="{item,index}">
          <span class="primary--text">
            #{{ index + 1 }}
          </span>
        </template>

        <template #[`header.expensesDescription`]>
          <span> {{ $t('expensesDescription') }} </span>
        </template>

        <template #[`item.expensesDescription`]="{item}">
          <v-textarea
            v-model="item.name"
            style="text-align-last: center;"
            auto-grow
            hide-details
            :rows="1"
            dense
            no-resize
            @input="totalSum"
          />
        </template>

        <template #[`header.supplierName`]>
          <span> {{ $t('supplierName') }} </span>
        </template>

        <template #[`item.supplierName`]="{item}">
          <v-textarea
            v-model="item.provider"
            style="text-align-last: center;"
            auto-grow
            hide-details
            :rows="1"
            dense
            no-resize
            @input="totalSum"
          />
        </template>
        <template #[`header.supplierPhoneNumber`]>
          <span> {{ $t('phoneNumber') }} </span>
        </template>

        <template #[`item.supplierPhoneNumber`]="{item}">
          <v-textarea
            v-model="item.phone"
            style="text-align-last: center;"
            auto-grow
            hide-details
            :rows="1"
            dense
            no-resize
            @input="totalSum"
          />
        </template>
        <template #[`header.totalPayment`]>
          <span> {{ $t('totalPayment') }} </span>
        </template>

        <template #[`item.totalPayment`]="{item}">
          <v-textarea
            v-model="item.totalAmount"
            style="text-align-last: center;"
            auto-grow
            hide-details
            :rows="1"
            dense
            no-resize
            @input="totalSum"
          />
        </template>
        <template #[`header.paidAmount`]>
          <span> {{ $t('paidAmount') }} </span>
        </template>

        <template #[`item.paidAmount`]="{item}">
          <v-textarea
            v-model="item.paidAmount"
            style="text-align-last: center;"
            auto-grow
            hide-details
            :rows="1"
            dense
            no-resize
            @input="totalSum"
          />
        </template>
        <template #[`header.leftToPay`]>
          <span> {{ $t('leftToPay') }} </span>
        </template>

        <template #[`item.leftToPay`]="{item}">
          <v-textarea
            v-if="item.isEditing"
            style="text-align-last: center;"
            readonly
            auto-grow
            hide-details
            dense
            no-resize
            :rows="1"
            :value="item.totalAmount >= 0 ? item.totalAmount - item.paidAmount : ''"
          />
        </template>
        <template #[`header.userComment`]>
          <span> {{ $t('comments') }} </span>
        </template>

        <template #[`item.userComment`]="{item}">
          <v-textarea
            v-model="item.notes"
            style="text-align-last: center;"
            auto-grow
            hide-details
            :rows="1"
            dense
            no-resize
            @input="totalSum"
          />
        </template>
        <template #[`item.deleteExpenses`]="{item}">
          <v-avatar
            class="v-avatar-light-bg primary--text"
            size="30"
          >
            <v-icon
              color="primary"
              small
              @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </v-avatar>
        </template>
      </v-data-table>
      <div class="text-center pt-2 pb-5">
        <v-pagination
          v-show="pageCount > 1"
          v-model="page"
          :length="pageCount"
          class="guest-table-pagination"
        ></v-pagination>
      </div>
    </v-card>
  </div>
</template>

<script>
import { i18n } from '@/plugins/i18n/index'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
import {
  mdiCalculator,
  mdiCheck,
  mdiContentSaveOutline,
  mdiCurrencyUsd,
  mdiFileMoveOutline,
  mdiPlus,
  mdiProgressClock,
} from '@mdi/js'
import { computed, ref } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import useFileDownload from '@/composables/useFileDownload'

export default {
  setup() {
    const sumTotalPayment = ref(0)
    const sumPaidAmount = ref(0)
    const sumLeftToPay = ref(0)

    const toast = useToast()
    const showToast = () => toast.error(i18n.t('errorManageEvents'))
    const { downloadFile } = useFileDownload()

    // pagination
    const itemsPerPage = ref(10)
    const page = ref(1)
    const pageCount = ref(0)

    const items = ref([
      {
        id: 1,
        isEditing: true,
        name: '',
        provider: '',
        phone: '',
        totalAmount: '',
        paidAmount: '',
        leftToPay: '',
        notes: '',
      },
    ])
    const tableColumns = computed(() => [
      {
        text: '',
        value: 'expensesNumber',
        width: '10',
        align: 'center',
        sortable: false,
      },
      {
        text: i18n.t('expensesDescription'),
        value: 'expensesDescription',
        width: 'auto',
        align: 'center',
        sortable: false,
      },
      {
        text: i18n.t('supplierName'),
        value: 'supplierName',
        width: 'auto',
        align: 'center',
        sortable: false,
      },
      {
        text: i18n.t('phoneNumber'),
        value: 'supplierPhoneNumber',
        width: '120',
        align: 'center',
        sortable: false,
      },
      {
        text: i18n.t('totalPayment'),
        value: 'totalPayment',
        width: 'auto',
        align: 'center',
        sortable: false,
      },
      {
        text: i18n.t('paidAmount'),
        value: 'paidAmount',
        width: 'auto',
        align: 'center',
        sortable: false,
      },
      {
        text: i18n.t('leftToPay'),
        value: 'leftToPay',
        width: 'auto',
        align: 'center',
        sortable: false,
      },
      {
        text: i18n.t('comments'),
        value: 'userComment',
        width: 'auto',
        align: 'center',
        sortable: false,
      },
      {
        text: '',
        value: 'deleteExpenses',
        width: '10',
        align: 'center',
        sortable: false,
      },
    ])

    const totalSum = () => {
      sumTotalPayment.value = 0
      sumPaidAmount.value = 0
      sumLeftToPay.value = 0

      items.value.forEach(el => {
        // if paid amount is bigger then total amount, set total amount to paid amount.
        if (Number(el.paidAmount) > Number(el.totalAmount)) {
          // eslint-disable-next-line no-param-reassign
          el.totalAmount = el.paidAmount
        }
        sumTotalPayment.value += Number(el.totalAmount)
        sumPaidAmount.value += Number(el.paidAmount)
        sumLeftToPay.value = sumTotalPayment.value - sumPaidAmount.value
      })
    }

    const getExpenses = () => {
      store
        .dispatch('getEventExpenses', { id: store.state.eventData.id })
        .then(res => {
          if (res.data.length > 0) {
            items.value = res.data.map(el => ({ ...el, isEditing: true, leftToPay: '' }))
          }
          totalSum()
        })
        .catch(() => showToast())
    }
    getExpenses()

    const saveItem = () => {
      const saveItems = items.value.map(el => ({
        id: el.id,
        name: el.name ?? '',
        notes: el.notes ?? '',
        paidAmount: el.paidAmount !== '' ? el.paidAmount : 0,
        phone: el.phone ?? '',
        provider: el.provider ?? '',
        totalAmount: el.totalAmount !== '' ? el.totalAmount : 0,
      }))

      store
        .dispatch('updateEventExpenses', { id: store.state.eventData.id, expenses: saveItems })
        .catch(() => showToast())
    }

    const deleteItem = item => {
      const index = items.value.indexOf(item)
      if (index !== -1) {
        items.value.splice(index, 1)
      }
      totalSum()
    }

    const addNewRow = () => {
      items.value = [
        ...items.value,
        {
          id: items.value.length + 1,
          isEditing: true,
          name: '',
          provider: '',
          phone: '',
          totalAmount: '',
          paidAmount: '',
          leftToPay: '',
          notes: '',
        },
      ]
    }

    const exportToExcel = () => {
      store
        .dispatch('exportDataToExcel', { id: store.state.eventData.id, lang: i18n.locale })
        .then(response => {
          const blob = new Blob([response.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          })

          downloadFile(blob, `${i18n.t('eventExpenses')}.xlsx`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
        })
        .catch(() => showToast())
    }

    return {
      items,
      saveItem,
      deleteItem,
      sumTotalPayment,
      sumPaidAmount,
      sumLeftToPay,
      totalSum,
      addNewRow,
      tableColumns,
      itemsPerPage,
      page,
      pageCount,
      exportToExcel,
      getExpenses,

      icons: {
        mdiCalculator,
        mdiFileMoveOutline,
        mdiPlus,
        mdiContentSaveOutline,
        mdiCheck,
        mdiProgressClock,
        mdiCurrencyUsd,
      },
    }
  },
}
</script>
<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.guest-table-pagination .v-pagination__item {
  border-radius: 50% !important;
}
</style>
